export const cookieAttributes = {
    secure: true,
    domain: process.env.COOKIE_DOMAIN || '',
    path: '/'
}

export const GAME_IDS = {
    warzone2: 110,
    warzone: 24,
    apex: 11,
    destiny: 100,
    valorant: 27,
    fortnite: 30,
}

const AUTH_COOKIE_POSTFIX = process.env.COOKIE_POSTFIX || '';

export const pwpAccess = `pwp-access${AUTH_COOKIE_POSTFIX}`;
export const pwpRefresh = `pwp-refresh${AUTH_COOKIE_POSTFIX}`;

export const LF2_MARK = process.env.LF2_COOKIE_NAME;
export const LF2_URL = process.env.LF2_URL;

export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

export const API_URL = process.env.PUBLIC_API_URL;
export const REDIRECT_URL = process.env.REDIRECT_URL || window.location.hostname;
export const CLIENT_MAIN_PAGE = "/client/main-page";
export const CLIENT_PRO_PLAYERS = "/client/pro-players";
