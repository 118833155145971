import Cookies from 'js-cookie'

import {cookieAttributes, GAME_IDS, pwpAccess, pwpRefresh, REDIRECT_URL} from "./constants";

export function setCookie(name, value, options = {}) {
  Cookies.set(name, value, options);
}

export const setRefreshToken = (value) => {
  setCookie(pwpRefresh, value, {
    ...cookieAttributes,
    expires: 30,
  })
}

export const setAccessToken = (value) => {
  setCookie(pwpAccess, value, {
    ...cookieAttributes,
    expires: 1/24,
  })
}

export function getCookie(name, options = {}) {
  return Cookies.get(name, options);
}

export function deleteCookie(name, options = {}) {
  Cookies.remove(name, options)
}

function parseJwt (token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export function checkTokenForExpire (tokenName = pwpAccess) {
  const token = getCookie(tokenName, cookieAttributes);

  if (token) {
    const parsedToken = parseJwt(token);
    const { exp } = parsedToken;
    const currentTimeInSeconds = new Date().getTime() / 1000;

    if (exp <= currentTimeInSeconds) {
      deleteCookie(tokenName);
      return false;
    }
    return true;
  }
  return false;
}

export function getCurrentActiveGameId () {
  try {
    const gameTitle = document.getElementsByName('gameTitle')[0].content;

    if (gameTitle) {
      return GAME_IDS[gameTitle];
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export const isLf2Page = () => {
  const pageType = document.head.querySelector("[property~=page-type][content]")?.content;

  return pageType === 'lf2';
}

export const getRedirectUrl = (path) => REDIRECT_URL + path;

